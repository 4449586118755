<template>
  <div>
    <div>
      <k-header-section
        header-text="Brands"
        item-label="Brands"
        :tabs-label="true"
        :total-items-count="itemsCount"
        :current-page-count="currentPageCount"
      >

        <div class="d-flex justify-content-between align-items-center inter-500-14">
          <k-button
            variant="outline-info"
            class="me-2"
            @click="exportUser(ROLES.BRAND)"
          >
            <kingpin-icon
              icon="DownloadCloudIcon"
              size="20"
              class="me-2"
            />
            Export
          </k-button>
          <k-button
            variant="info"
            @click="goToPreapprovedSignupPage"
          >
            <kingpin-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="ps-2 text-uppercase"> PREAPPROVED USER</span>
          </k-button>
        </div>
      </k-header-section>
    </div>
    <div>
      <div class="table-padding">
        <div class="filter-sorting-container">
          <k-search-bar-section
            placeholder="Search By Brand, Company name, Email..."
            hide-sort-option
            hide-filter-option
            hide-column-edit-option
            @on-search="updateSearch"
          />
          <multiple-filter
            class="ms-2"
            :filter-fields="filterFields"
            :extra-query-for-string-filter="{role: ROLES.BRAND}"
            @on-apply-filter="onApplyFilter"
          />
          <k-sorting
            class="ms-auto"
            hide-column-edit-option
            :sort-items="fields"
            @update-sort="updateSort"
            @update-sort-direction="updateSortDirection"
          />
        </div>
        <div
          v-if="loading"
          class="spinner-body"
        >
          <b-spinner />
        </div>
        <div
          v-else
          class="table-responsive"
        >
          <!-- Table section -->
          <p-table
            :fields="fields"
            :items="items"
          >
            <template #empty>
              <p
                class="
                  h2
                  d-flex
                  table-empty-message
                  justify-content-center
                  align-items-center
                  gap-2
                "
              >
                <b-icon
                  icon="exclamation-circle"
                  scale="1"
                />
                <span> No items found </span>
              </p>
            </template>
            <template #cell(_id)="data">
              <b-link
                class="text-decoration-none"
                :to="{path: `/user-details/${data.item._id || data.item.id}?entityType=brand`}"
              >
                {{ data.item._id || data.item.id }}
              </b-link>
            </template>
            <template #cell(status)="data">
              <span class="text-capitalize">{{ data.item.status }}</span>
            </template>
            <template #cell(entityName)="data">
              <span class="text-primary text-capitalize text-nowrap">{{
                data.item.entityName
              }}</span>
            </template>
            <template #cell(companyName)="data">
              <span class="text-primary text-capitalize text-nowrap">{{
                data.item.companyName
              }}</span>
            </template>
            <template #cell(profileLink)="data">
              <div
                v-if="data.item.profileLink"
                class="d-flex flex-row align-items-center"
              >
                <b-link
                  class="
                  d-inline-block
                  text-truncate
                  table-url-item
                  text-decoration-none
                "
                  :href="data.item.profileLink"
                  target="_blank"
                >
                  {{ data.item.profileLink }}
                </b-link>
                <span
                  class="ms-1 cursor-pointer"
                  @click="onClickCopy(data.item.profileLink)"
                ><copy-text-icon /></span>
              </div>
            </template>
            <template #cell(collectionName)="data">
              <span class="text-primary text-capitalize">{{
                data.item.collectionName
              }}</span>
            </template>
            <template #cell(collectionUpdatedDate)="data">
              <span v-if="data.item.collectionUpdatedDate">{{
                formattedDate(data.item.collectionUpdatedDate)
              }}</span>
            </template>
            <template #cell(updatedAt)="data">
              <span>{{
                formattedDate(data.item.updatedAt)
              }}</span>
            </template>
          </p-table>
        </div>
        <k-table-pagination
          :total-items="itemsCount"
          @emit-current-page="updateCurrentPage"
          @emit-per-page="updatePerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formattedDate } from '@/@core/utils/format'
import { apiToastError } from '@/@core/utils/toast'
import CopyTextIcon from '@/assets/images/svg/CopyTextIcon.vue'
import { copyToClipboard } from '@/common-utils'
import { ROLES } from '@/constants'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { PTablePaginationMixin } from '@/mixins/p-table.mixin'
import { getBrandUsersFields } from '@/table-fields-constants'
import {
  KButton,
  KHeaderSection,
  KSearchBarSection,
  KTablePagination,
  KingpinIcon,
  PTable,
} from '@kingpin-global/kingpin-ui'
import { BLink } from 'bootstrap-vue'
import { exportUser, goToPreapprovedSignupPage } from '../users-utils'

export default {
  name: 'BrandList',
  components: {
    KHeaderSection,
    PTable,
    KTablePagination,
    KSearchBarSection,
    CopyTextIcon,
    BLink,
    KButton,
    KingpinIcon,
  },
  mixins: [KSearchAndSortMixin, PTablePaginationMixin],
  data() {
    return {
      loaded: false,
      fields: getBrandUsersFields(),
      items: [],
      formattedDate,
      goToPreapprovedSignupPage,
      ROLES,
      exportUser,
    }
  },
  computed: {
    loading() {
      return !(this.items.length || this.loaded)
    },
  },
  created() {
    this.initPageContent()
  },
  methods: {
    initPageContent() {
      if (this.items.length > 0) {
        this.updateFilteredItemAndPaginationParams(this.items)
      }
      let queryParams = ''
      if (this.searchText) {
        queryParams = `search=email=regex=${this.searchText.toLowerCase()},entityName=regex=${this.searchText.toLowerCase()}`
      }
      const pagination = `pageNumber=${this.currentPage}&pageSize=${this.perPage}`
      queryParams = queryParams ? `${queryParams}&${pagination}` : `${pagination}`
      if (this.sortBy) {
        queryParams += `&sortCriteria=${this.sortBy}&sortOrder=${this.isDesc ? 'DESC' : 'ASC'}`
      } else {
        queryParams += '&sortCriteria=updatedAt&sortOrder=DESC'
      }
      this.loadUsers(queryParams)
    },
    async loadUsers(queryParams) {
      const url = `${this.$kpEndpoint.brand.getUsers.url}?${queryParams}`
      await this.$kpRequest({ ...this.$kpEndpoint.brand.getUsers, url })
        .then(res => {
          const data = res.data.data
          this.items = data.docs
          this.itemsCount = data.count
          this.updateFilteredItemAndPaginationParams(this.items)
          this.setFilterFields(data.filter, this.fields)
          this.loaded = true
        })
        .catch(err => {
          apiToastError(err)
          this.loaded = false
        })
    },
    onClickCopy(text) {
      copyToClipboard(text, 'Profile link was saved to your clipboard successfully!')
    },
  },
}
</script>
